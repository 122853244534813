import { customValidators } from "@/i18n/utils/i18n-validators";
import { isInNuxtInstance } from "@/utils/isInNuxtInstance";

export const formProductNotifyMeValidationRules = () => {
  if (!isInNuxtInstance())
    return {
      email: {
        required: () => true,
        email: () => true,
      },
    };
  const { required, email } = customValidators();

  return {
    email: {
      required,
      email,
    },
  };
};
